<template>
<section class="login_wrap bg-white">
	<div class="row no-gutters min-vh-100">

		<div class="col-lg-5 d-none d-lg-block bg-primary login_slider_bg" style="background-image: url('images/login/login_bg.png');">
			    <a class="p-3 d-inline-block login_logo" href="">
	            <img class="w-80" src="/images/logo.svg" alt="" />
         	</a>
         	<div class="login_slider_box">
		   		<div class="text-center pb-2">
		   			<div class="login_slider_box_img">
	    				<img class="d-inline-block mw-100" src="/images/login/login_img.png" alt="">
	    			</div>
	    		</div>
	    		<div class="text-white text-center">
	    			<h1 class="text-white">Protect Your Nest Egg and Enjoy Your Retirement</h1>
	    			<p class="font-size-lg">We're here to help. The Heyday team has worked with hundreds of retirees to help minimize financial risk and maximize their income. </p>
	    		</div>
	    	</div>
		</div>

    <div class="col-lg-7 d-flex align-items-center justify-content-center">
      <div class="login_box">
        <div class="login_box_form">
          <a class="login_tab_logo d-inline-block d-lg-none mb-4" href="">
              <img src="/images/HeyDay-Logo.svg" alt="Logo" />
          </a>
          <h5 class="text-primary mb-3">
                Welcome! <span class="font-size-lg">👋</span>
            </h5>
            <h2 class="text-primary mb-3 font-size-sm">
                Let's quickly get you logged in so you don't lose your results.
            </h2>
          <div class="mb-4">
              <a href="javascript:void(0);" class="btn btn-lg btn-light btn-block text-muted shadow" @click="socialLogin">
                <img src="/images/login/google-icon.svg" alt="">
                SIGN-IN WITH GOOGLE
              </a>
          </div>
          <div class="mb-3">
              <a href="javascript:void(0);" class="btn btn-lg btn-light btn-block text-muted shadow" @click="socialLoginFb">
                <img src="/images/login/facebook-icon.svg" alt="">
                SIGN-IN WITH FACEBOOK
              </a>
          </div>
          <div class="full_or border-bottom border-gray-200 mb-4 text-center">
                  <span class="bg-white px-2 text-muted position-relative">OR</span>
              </div>
              <div class="mb-4">
              <router-link to="/loginEmail" class="btn btn-primary btn-lg btn-block">
                <i class="fas fa-envelope pr-1"></i>
                SIGN-IN WITH EMAIL
              </router-link>
          </div>
        </div>
        <div class="login_box_copyright font-size-sm text-secondary">
          Copyright {{ new Date().getFullYear() }} <a href="https://www.heydayretirement.com/" target="_blank" class="text-secondary">Heydayretirement.com</a> All rights reserved.
        </div>
      </div>
    </div>

   </div>
</section>
</template>

<script>
import {firebase} from "@/utils/firebase.js";

export default {
  name: 'Login',
  methods: {
     socialLogin() {
        const provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().signInWithPopup(provider).then((result) => {
          this.$store.dispatch('setUserLoginData', result.user);
          this.$router.replace({ name: 'dashboard'});
        }).catch((err) => {
          alert('Oops. ' + err.message)
        });
    },
    socialLoginFb() {
        const provider = new firebase.auth.FacebookAuthProvider();
        firebase.auth().signInWithPopup(provider).then((result) => {
          this.$store.dispatch('setUserLoginData', result.user);
          this.$router.replace({ name: 'dashboard'});
        }).catch((err) => {
          alert('Oops. ' + err.message)
        });
    }
  }
};
</script>
